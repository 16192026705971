var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export function handleApiResponse(response) {
    var _a, _b;
    if (((_a = response === null || response === void 0 ? void 0 : response.status) === null || _a === void 0 ? void 0 : _a.toString()) !== '200' &&
        ((_b = response === null || response === void 0 ? void 0 : response.status) === null || _b === void 0 ? void 0 : _b.toString()) !== '201') {
        const error = response;
        return Promise.reject(error);
    }
    window.dispatchEvent(new Event('RESET_SESSION_TIMEOUT'));
    return response.data.result;
}
export function handleApiResponseOther(response) {
    var _a, _b;
    if (((_a = response === null || response === void 0 ? void 0 : response.status) === null || _a === void 0 ? void 0 : _a.toString()) !== '200' &&
        ((_b = response === null || response === void 0 ? void 0 : response.status) === null || _b === void 0 ? void 0 : _b.toString()) !== '201') {
        const error = response === null || response === void 0 ? void 0 : response.statusText;
        return Promise.reject(error);
    }
    window.dispatchEvent(new Event('RESET_SESSION_TIMEOUT'));
    return response.data;
}
export const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    config.headers['X-SMY-TenantID'] = 'anonymous';
    return config;
}), (error) => 
// Do something with request error
Promise.reject(error));
