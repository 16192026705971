import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LandingPage from './LandingPage';
import ExamLanding from './Pages/ExamLanding';
import Registration from './Pages/Registration';
import { getExamById, getTenantDetailByCode, } from '../store/actions';
const Redirect = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { examID } = params;
    if ((examID === null || examID === void 0 ? void 0 : examID.startsWith('REG')) && examID.match(/REG[A-Za-z0-9-]+/)) {
        const tenantCode = examID.slice(3);
        return React.createElement(Registration, { tenantCode: tenantCode });
    }
    if ((examID === null || examID === void 0 ? void 0 : examID.startsWith('TLP')) && examID.match(/TLP[A-Za-z0-9-]+/)) {
        const tenantCode = examID.slice(3);
        dispatch(getTenantDetailByCode({
            tenantCode,
        }));
        return React.createElement(LandingPage, null);
    }
    dispatch(getExamById(params));
    return React.createElement(ExamLanding, null);
};
export default Redirect;
